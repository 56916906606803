<!--
 * @Author: your name
 * @Date: 2020-03-16 23:48:57
 * @LastEditTime: 2020-10-26 16:46:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\allInformation\allinformationConfDetails.vue
-->
<template>
  <div class="informationDetails commonF graphShadow paddingB20">
    <infoTable :tableData="tableData" :num="1" />
    <div class="content wrapperS">
      <dir class="title">消息内容：</dir>
      <div v-html="this.viewData.msgContent" class="txt"></div>
    </div>
    <div class="goback">
      <el-button type="primary" @click="$router.go('-1')">返回</el-button>
    </div>
  </div>
</template>
<script>
import infoTable from "@/components/infoTable/infoTable";
export default {
  components: {
    infoTable,
  },
  data() {
    return {
      tableData: [],
      viewData: [],
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/acb/2.0/sysmgMsgConf/getById", {
          data: {
            msgConfId: this.$route.query.msgConfId,
          },
        })
        .then((res) => {
          this.viewData = res.value;
          this.setTableData();
        });
    },
    setTableData() {
      this.tableData = [
        {
          value: this.viewData.msgTitle,
          name: "消息标题：",
        },
        {
          value: this.viewData.editorName,
          name: "发送人：",
        },
        {
          value: this.viewData.sendType == 0 ? "站内消息" : "邮件",
          name: "消息类型：",
        },
        {
          value: this.viewData.sendTime,
          name: "发送日期：",
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="stylus" scoped>
.content {
  overflow hidden;
  width 100%
  border: 1px solid #ebeef5;
  border-top none;
  .title {
    width: 30%;
    margin 0;
    padding 0;
    float: left;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #f5f7fa;
  }
  .txt {
    box-sizing border-box!important;
    width 65%;
    line-height 30px;
    text-align center;
    float left;
  }
}
.goback {
  text-align center
  margin-top 30px;
}
</style>
